import React, { useState } from "react";
import { Link } from 'react-router-dom';
import NJPhLogo from '../assets/images/nj-ph-logo.png'


function HomeTopAppBar() {
    
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    return (
        <div 
            data-animation="default" 
            data-collapse="medium" 
            data-duration="400" 
            data-easing="ease" 
            data-easing2="ease" 
            role="banner" 
            className="navbar-2 w-nav"
        >
            <div className="w-container">
                <Link to="/" className="w-nav-brand">
                    <img 
                        src={NJPhLogo} 
                        loading="lazy" 
                        width="50" 
                        height="auto" 
                        alt="" 
                        sizes="50px" 
                        className="image-2"
                    />
                </Link>
                    <nav role="navigation" className="nav-menu w-nav-menu">
                        <Link to="/upcoming-events" className="nav-link w-nav-link" style={{maxWidth: '728px'}}>UPCOMING EVENT</Link>
                        <Link to="/past-events" className="nav-link w-nav-link" style={{maxWidth: '728px'}}>PAST EVENTS</Link>
                        <Link to="/about-us" className="nav-link">ABOUT US</Link>
                        <Link to="/contact" className="nav-link w-nav-link" style={{maxWidth: '728px'}}>CONTACT</Link>
                    </nav>
                <div 
                    className={`w-nav-button ${isMenuOpen ? 'w--open' : ''}`}
                    style={{WebkitUserSelect: 'text'}} 
                    aria-label="menu" 
                    role="button" 
                    tabIndex="0" 
                    aria-controls="w-nav-overlay-0" 
                    aria-haspopup="menu" 
                    aria-expanded={isMenuOpen ? "true" : "false"}
                    onClick={toggleMenu}
                >
                    <div className="w-icon-nav-menu"></div>
                </div>
            </div>
            <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style={isMenuOpen ? { display: 'block', height: '7814.38px' } : { display: 'none' }}>
                {isMenuOpen && (
                    <nav 
                        role="navigation" 
                        className="nav-menu w-nav-menu" 
                        style={{ transform: 'translateY(0px) translateX(0px)', transition: 'transform 400ms ease 0s' }} 
                        data-nav-menu-open
                    >
                            <Link to="/upcoming-events" className="nav-link w-nav-link w--nav-link-open" style={{ maxWidth: '728px' }}>UPCOMING EVENT</Link>
                            <Link to="/past-events" className="nav-link w-nav-link w--nav-link-open" style={{ maxWidth: '728px' }}>PAST EVENTS</Link>
                            <Link to="/about-us" className="nav-link w-nav-link w--nav-link-open">ABOUT US</Link>
                            <Link to="/contact" className="nav-link w-nav-link w--nav-link-open" style={{ maxWidth: '728px' }}>CONTACT</Link>
                    </nav>
                )}
            </div>
        </div>
    );
}

export default HomeTopAppBar;