import React from "react";
import NjPhBinkyBong from '../assets/images/nj-ph-binky-bong.png'
import NjPhBernice from '../assets/images/nj-ph-bernice.png'
import NjPhPoppeShopSponsor from '../assets/images/nj-ph-poppe-shop-sponsor.jpg'
import NjPhAmosSponsor from '../assets/images/nj-ph-amos-sponsor.jpg'
import NjPhCubSponsors from '../assets/images/nj-ph-cub-sponsors.jpg'
import NjPhCometsCrushSponsor from '../assets/images/nj-ph-comets-crush-sponsor.jpg'
import NjPhHaerinPh from '../assets/images/nj-ph-haerin-ph.png'
import NjPhHanniBee from '../assets/images/nj-ph-hannibee.png'
import NjPhHanniAesthetic from '../assets/images/nj-ph-hanni-aesthetic.png'
import NjPhHanniPhamPh from '../assets/images/nj-ph-hanni-pham-ph.png'
import NjPhHanniPhilippines from '../assets/images/nj-ph-hanni-philippines.png'

function HomeSection4() {
    return(
        <section className="section-4">
            <img 
                src={NjPhBinkyBong} 
                loading="lazy" 
                data-w-id="aa8cbd27-de87-1598-1f6f-c0216f62630e" 
                sizes="(max-width: 479px) 40vw, (max-width: 991px) 30vw, (max-width: 5320px) 20vw, 1064px" 
                alt=""  
                className="image-7" 
                style={{
                    willChange: 'transform',
                    // transform: 'translate3d(4px, 263px, 317px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                    transformStyle: 'preserve-3d'
                    }}
            />
            <div 
                data-w-id="58215a7c-abcd-1984-52e5-f52ece57a7c8" 
                className="w-layout-vflex flex-block-7" 
                style={{
                    willChange: 'transform',
                    // transform: 'translate3d(0px, 21.0981px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                    transformStyle: 'preserve-3d'
                    }}
            >
                <h1 className="heading">PARTNERS &amp; SPONSORS</h1>
                <p className="text-block-2">We reach out our heartfelt gratitude towards our sponsors and partners for making this event possible.</p>
            </div>
            <div className="w-layout-blockcontainer container-9 w-container">
                <img 
                    src={NjPhBernice}
                    loading="lazy" 
                    data-w-id="f9cafbc8-aac5-5170-1102-631a318e042a" 
                    alt="" 
                    className="image-8" 
                    style={{
                        willChange: 'transform',
                        // transform: 'translate3d(4px, 263px, 317px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                        transformStyle: 'preserve-3d'
                        }}
                />
                <div 
                    id="w-node-_6f40c623-b743-988c-5eb9-3edae374df02-38a13a3a" 
                    data-w-id="6f40c623-b743-988c-5eb9-3edae374df02" 
                    className="w-layout-layout quick-stack-2 wf-layout-layout" 
                    style={{
                        willChange: 'transform',
                        // transform: 'translate3d(0px, 39.3449px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                        transformStyle: 'preserve-3d'
                        }}
                >
                    <div id="w-node-_6f40c623-b743-988c-5eb9-3edae374df03-38a13a3a" className="w-layout-cell cell-3">
                        <h4 className="h4">POPPE&nbsp;SHOP</h4>
                        <a 
                            href="https://linktr.ee/poppeshop?fbclid=IwZXh0bgNhZW0CMTAAAR1ZTdv1Hj1ejULKX8uuZ7iUsnuarecESPwk-vEktpDf-8MrkZKo0Irelik_aem_VhZBgkB9vmpDmMoppDwTNg" 
                            className="w-inline-block"
                        >
                            <img 
                                src={NjPhPoppeShopSponsor}
                                loading="lazy" 
                                sizes="(max-width: 479px) 300px, (max-width: 767px) 500px, (max-width: 991px) 700px, 940px" 
                                alt=""
                            />
                        </a>
                        <div className="w-layout-hflex flex-block-8">
                            <a href="https://x.com/poppeshop" className="link">Twitter</a>
                            <a href="https://www.facebook.com/poppeshop" className="link">Facebook</a>
                        </div>
                    </div>
                    <div id="w-node-_6f40c623-b743-988c-5eb9-3edae374df04-38a13a3a" className="w-layout-cell cell-3">
                        <h4 className="h4">AMOS THE FANBOY</h4>
                        <a href="https://www.facebook.com/amosthefanboy" className="w-inline-block">
                            <img 
                                src={NjPhAmosSponsor} 
                                loading="lazy" 
                                sizes="(max-width: 479px) 300px, (max-width: 767px) 500px, (max-width: 991px) 700px, 940px" 
                                alt=""
                            />
                        </a>
                        <div className="w-layout-hflex flex-block-8">
                            <a href="https://x.com/amosthefanboy" className="link">Twitter</a>
                            <a href="https://www.facebook.com/amosthefanboy" className="link">Facebook</a>
                        </div>
                    </div>
                    <div id="w-node-_0ba1e501-a8d8-8c2c-2199-c22fc2d0b6bb-38a13a3a" className="w-layout-cell cell-3">
                        <h4 className="h4">HELLO&nbsp;CUB&nbsp;PH</h4>
                        <a href="https://linktr.ee/hellocubph" className="w-inline-block">
                            <img 
                                src={NjPhCubSponsors}
                                loading="lazy" 
                                sizes="(max-width: 479px) 300px, (max-width: 767px) 500px, (max-width: 991px) 700px, 940px" 
                                alt=""
                            />
                        </a>
                        <div className="w-layout-hflex flex-block-8">
                            <a href="https://x.com/HelloCubPH" className="link">Twitter</a>
                            <a href="https://www.facebook.com/hellocubph" className="link">Facebook</a>
                        </div>
                    </div>
                    <div id="w-node-_5b659c8d-1298-6abe-29cb-aa4b65099b7a-38a13a3a" className="w-layout-cell cell-3">
                        <h4 className="h4">COMETS CRUSH</h4>
                        <a href="https://cometscrush.company.site/" className="w-inline-block">
                            <img 
                                src={NjPhCometsCrushSponsor}
                                loading="lazy" 
                                sizes="(max-width: 479px) 300px, (max-width: 767px) 500px, (max-width: 991px) 700px, 940px" 
                                alt=""
                            />
                        </a>
                        <div className="w-layout-hflex flex-block-8">
                            <a href="https://x.com/cometscrush" className="link">Twitter</a>
                            <a href="https://www.facebook.com/cometscrush" className="link">Facebook</a>
                        </div>
                    </div>
                </div>
            </div>
            <div data-w-id="ec99c507-adff-648f-900b-cacb41fedd1d" className="div-block-9" 
                style={{
                    willChange: 'transform',
                    // transform: 'translate3d(0px, 60px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                    transformStyle: 'preserve-3d'
                    }}
            >
                <h2 className="h2">FANDOM PARTNERS</h2>
                <div id="w-node-f13ff4bb-62a3-5b07-8aee-6247f9654b02-38a13a3a" className="w-layout-layout quick-stack-3 wf-layout-layout">
                    <div id="w-node-f13ff4bb-62a3-5b07-8aee-6247f9654b03-38a13a3a" className="w-layout-cell cell-4">
                        <div className="w-layout-vflex flex-block-9">
                            <a href="https://www.facebook.com/KangHaerinPH" target="_blank" rel="noopener noreferrer" className="link-block-3 w-inline-block">
                                <img 
                                    src={NjPhHaerinPh}
                                    loading="lazy" 
                                    sizes="(max-width: 479px) 179.9921875px, (max-width: 767px) 329.984375px, (max-width: 991px) 399.9921875px, 176px"
                                    alt="" 
                                    className="image-15"
                                />
                            </a>
                        </div>
                    </div>
                    <div id="w-node-f13ff4bb-62a3-5b07-8aee-6247f9654b04-38a13a3a" className="w-layout-cell cell-5">
                        <div class="div-block-4">
                            <a href="https://www.facebook.com/NewJeansHannibee" target="_blank" rel="noopener noreferrer" className="link-block-4 w-inline-block">
                                <img 
                                    src={NjPhHanniBee}
                                    loading="lazy" 
                                    sizes="(max-width: 479px) 199.9765625px, (max-width: 767px) 359.9921875px, (max-width: 991px) 449.984375px, 150px"
                                    alt="" 
                                    className="image-13"
                                />
                            </a>
                        </div>
                    </div>
                    <div id="w-node-_8f950526-ba71-a65e-cdc0-ea7b73dbd1be-38a13a3a" className="w-layout-cell cell-6">
                        <div className="div-block-6">
                            <a href="https://www.facebook.com/profile.php?id=100085698288396" target="_blank" rel="noopener noreferrer" className="link-block-2 w-inline-block">
                                <img 
                                    src={NjPhHanniAesthetic}
                                    loading="lazy" 
                                    sizes="(max-width: 479px) 100px, (max-width: 991px) 150px, 108px" 
                                    alt="" 
                                    className="image-12"
                                />
                            </a>
                        </div>
                    </div>
                    <div id="w-node-_204d8686-9dc4-59e4-6950-491bb112493a-38a13a3a" className="w-layout-cell cell-7">
                        <div className="div-block-5">
                            <a href="https://www.facebook.com/HanniPhamPhilippines" target="_blank" rel="noopener noreferrer" className="link-block-5 w-inline-block">
                                <img 
                                    src={NjPhHanniPhamPh}
                                    loading="lazy" 
                                    sizes="(max-width: 479px) 219.9765625px, (max-width: 767px) 359.9609375px, (max-width: 991px) 449.9609375px, 160px" 
                                    srcset="https://cdn.prod.website-files.com/668f50cba33dd3f638a13a34/6696852ddbde33199bceb7c1_Hanni%20Pham%20PH-p-500.png 500w, https://cdn.prod.website-files.com/668f50cba33dd3f638a13a34/6696852ddbde33199bceb7c1_Hanni%20Pham%20PH-p-800.png 800w, https://cdn.prod.website-files.com/668f50cba33dd3f638a13a34/6696852ddbde33199bceb7c1_Hanni%20Pham%20PH-p-1080.png 1080w, https://cdn.prod.website-files.com/668f50cba33dd3f638a13a34/6696852ddbde33199bceb7c1_Hanni%20Pham%20PH-p-1600.png 1600w, https://cdn.prod.website-files.com/668f50cba33dd3f638a13a34/6696852ddbde33199bceb7c1_Hanni%20Pham%20PH-p-2000.png 2000w, https://cdn.prod.website-files.com/668f50cba33dd3f638a13a34/6696852ddbde33199bceb7c1_Hanni%20Pham%20PH.png 2421w" 
                                    alt="" 
                                    className="image-16"
                                />
                            </a>
                        </div>
                    </div>
                    <div id="w-node-c3163be2-5834-6983-418d-2312fb248e11-38a13a3a" className="w-layout-cell cell-8">
                        <div className="div-block-7">
                            <a href="https://www.facebook.com/hanniphils" target="_blank" rel="noopener noreferrer" className="link-block-6 w-inline-block">
                                <img 
                                    src={NjPhHanniPhilippines}
                                    loading="lazy" 
                                    sizes="(max-width: 479px) 159.9921875px, (max-width: 991px) 309.9921875px, 180px" 
                                    alt="" 
                                    className="image-14"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeSection4;