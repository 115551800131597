// src/pages/Contact.js
import React from 'react';

function Contact() {
    return (
        <div>
            <h1>Contact</h1>
            {/* Add your content here */}
        </div>
    );
}

export default Contact;
