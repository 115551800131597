import React from "react";
import { Helmet } from "react-helmet-async";

function MainHelmet() {
    return (
        <Helmet>
            <title>Club0722</title>
        </Helmet>
    );
}

export default MainHelmet;
