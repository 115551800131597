// src/pages/PastEvents.js
import React from 'react';

function PastEvents() {
    return (
        <div>
            <h1>Past Events</h1>
            {/* Add your content here */}
        </div>
    );
}

export default PastEvents;
