import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import NjPhHeart from '../assets/images/nj-ph-heart.png';
import NjPhDonut from '../assets/images/nj-ph-donut.png';
import NjPhInclusionReveal1 from '../assets/images/nj-ph-inclusion-reveal1.jpeg';
import NjPhInclusionReveal2 from '../assets/images/nj-ph-inclusion-reveal2.jpeg';
import NjPhPrizesSponsorship from '../assets/images/nj-ph-prizes-sponsorship.jpg'

function HomeSection3() {
    const [open, setOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const slides = [
        { src: NjPhInclusionReveal1, alt: "Image 1" },
        { src: NjPhInclusionReveal2, alt: "Image 2" }
    ];

    const openLightbox = (index) => {
        setPhotoIndex(index);
        setOpen(true);
    };

    return (
        <section className="section-3">
            <div className="w-layout-vflex flex-block-5">
                <h1 className="heading">JOIN OUR UPCOMING CUPSLEEVE EVENT!</h1>
                <p className="text-block-2">Our event will be packed with exciting games and raffles, ensuring that everyone who participates has a chance to win amazing prizes!</p>
            </div>
            <div className="w-layout-blockcontainer container-8 w-container">
                <div className="w-layout-vflex flex-block-6">
                    <h2 className="h2-dark">REGISTRATION</h2>
                    <div id="w-node-_198570f0-ea4e-da5f-79f1-35a57fae78bc-38a13a3a" className="w-layout-layout quick-stack wf-layout-layout">
                        <div id="w-node-_198570f0-ea4e-da5f-79f1-35a57fae78bd-38a13a3a" className="w-layout-cell cell">
                            <img src={NjPhHeart} loading="lazy" alt="" className="image-4"/>
                            <div className="text-block-3">200 PHP</div>
                            <p className="paragraph-3">Sign up using our Google form by clicking the button below. For just PHP 200, you’ll receive a variety of collectibles and free goodies. Don’t miss out—join us now!</p>
                        </div>
                        <div id="w-node-_198570f0-ea4e-da5f-79f1-35a57fae78be-38a13a3a" className="w-layout-cell cell-2">
                            <img src={NjPhDonut} loading="lazy" alt="" className="image-5"/>
                            <div className="text-block-3">100 PHP</div>
                            <p className="paragraph-3">Kindly support our venue sponsor by purchasing food from them. Your PHP 100 will go a long way in strengthening our continued partnership with them. Thank you!</p>
                        </div>
                    </div>
                    <div className="div-block-2">
                        <a href="https://forms.gle/iDhUNPxW83kwGvWAA" target="_blank" rel="noopener noreferrer" className="button w-button">REGISTER HERE</a>
                    </div>
                    <h2 className="h2-dark">INCLUSIONS</h2>
                    <div id="w-node-_79a6c40d-b968-d1cb-c301-12e8160a1fbb-38a13a3a" className="w-layout-layout wf-layout-layout">
                        <div id="w-node-_79a6c40d-b968-d1cb-c301-12e8160a1fbc-38a13a3a" className="w-layout-cell">
                            <button className="w-inline-block w-lightbox" onClick={() => openLightbox(0)}>
                                <img src={NjPhInclusionReveal1} loading="lazy" sizes="(max-width: 1024px) 100vw, 1024px" alt=""/>
                            </button>
                        </div>
                        <div id="w-node-_79a6c40d-b968-d1cb-c301-12e8160a1fbd-38a13a3a" className="w-layout-cell">
                            <button className="w-inline-block w-lightbox" onClick={() => openLightbox(1)}>
                                <img src={NjPhInclusionReveal2} loading="lazy" sizes="(max-width: 1024px) 100vw, 1024px" alt=""/>
                            </button>
                        </div>
                    </div>
                    <h2 className="h2-dark">GIVEAWAYS</h2>
                    <div id="w-node-_16e25ea9-adfb-217c-34ed-98c931baf15b-38a13a3a" className="w-layout-layout wf-layout-layout">
                        <div id="w-node-d216f3cb-f342-fbb5-3c2f-bddc9b15f088-38a13a3a" className="w-layout-cell">
                            <img src={NjPhPrizesSponsorship} loading="lazy" sizes="100vw"  alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div data-w-id="3a3cef63-41c6-2dc8-3a68-5cbbcf4c53bb" className="w-layout-vflex flex-block-4">
                <h2 className="h2-dark">LOCATION</h2>
                <div 
                    className="map w-widget w-widget-map" 
                    data-widget-style="roadmap" 
                    data-widget-latlng="14.6199284,121.0722941" 
                    aria-label="Home Table Deli" 
                    data-enable-scroll="true" 
                    role="region" 
                    title="Home Table Deli" 
                    data-enable-touch="true" 
                    data-widget-zoom="16" 
                    data-widget-tooltip="CSE Location" 
                    style={{ overflow: 'hidden' }}
                >
                    <iframe 
                        title="Home Table Deli"
                        width="100%" 
                        height="100%" 
                        allowFullScreen={true}
                        style={{ top: 0, left: 0, position: "absolute"}}
                        loading="lazy"
                        id="gmap_canvas1"
                        referrerPolicy="no-referrer-when-downgrade"
                        src="https://maps.google.com/maps?width=523&amp;height=403&amp;hl=en&amp;q=Home%20Table%20Deli,%20YDG%20Building,%20219%20Katipunan%20Ave,%20Quezon%20City,%20Metro%20Manila%20Quezon+()&amp;t=p&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    />
                </div>
            </div>

            {open && (
                <Lightbox
                    plugins={[Thumbnails]}
                    thumbnails={{
                        vignette: false,
                        imageFit: "cover",
                    }}
                    open={open}
                    close={() => setOpen(false)}
                    slides={slides}
                    index={photoIndex}
                    styles={
                        {
                            container: {background: "rgba(0, 0, 0, .9)"}, 
                            thumbnailsContainer:{background: "rgba(0, 0, 0, .9)"},
                        }
                    }
                    carousel={{
                        finite: true,
                    }}
                />
            )}
        </section>
    );
}

export default HomeSection3;
