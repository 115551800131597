import React from "react";
import NjPhVidBg from '../assets/videos/njphvidbg.mp4'
import NjPhWebmBg from '../assets/webm/njphwebmbg.webm'
import NjPhImgBg from '../assets/images/njphimgbg.jpg'

function HomeVideoBg(){
    return (
        <div 
            data-autoplay="true"
            data-loop="true"
            data-wf-ignore="true"
            className="background-video w-background-video w-background-video-atom"
            >
                <video 
                    id="774b8391-1bff-ec02-a90e-f932e3024c01-video" 
                    autoPlay
                    loop
                    style={{ backgroundImage: `url(${NjPhImgBg})`}}
                    muted 
                    playsInline
                    data-wf-ignore="true"
                    data-object-fit="cover"
                    >
                        <source 
                            src={NjPhVidBg}
                            type="video/mp4"
                            data-wf-ignore="true"
                            >
                        </source>
                        <source 
                            src={NjPhWebmBg}
                            type="video/webm"
                            data-wf-ignore="true"
                            >
                        </source>
                        Your browser doesn't support HTML video.
                </video>
        </div>
    )
}

export default HomeVideoBg;