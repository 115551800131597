// src/pages/UpcomingEvents.js
import React from 'react';

function UpcomingEvents() {
    return (
        <div>
            <h1>Upcoming Events</h1>
            {/* Add your content here */}
        </div>
    );
}

export default UpcomingEvents;
