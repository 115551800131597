import React from "react";
import SweetCityDreams from '../assets/images/nj-ph-sweet-city-dreams.png'

function HomeSection2(){
    return (
        <section className="section-2">
                <div className="w-layout-blockcontainer container-6 w-container">
                    <div className="div-block">
                        <img 
                            src={SweetCityDreams}
                            loading="lazy" 
                            width="auto" 
                            data-w-id="d454ae65-6b76-65e5-6c68-fdede688bd6c" 
                            alt="" 
                            sizes="(max-width: 479px) 90vw, 100vw" 
                            className="image" 
                            style={{
                                willChange: 'transform', 
                                transform: 'translate3d(0px, 152.622px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', 
                                transformStyle: 'preserve-3d'
                            }}
                        />
                        <div 
                            data-w-id="29585d3c-c6f2-9a25-5046-a9a44e0e6af2" 
                            className="div-block-8" 
                            style={{
                                willChange: 'transform', 
                                transform: 'translate3d(0px, 240px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', 
                                transformStyle: 'preserve-3d'
                            }}
                        >
                            <h2 className="h2">
                                NEWJEANS 2ND ANNIVERSARY<br />
                                CUPSLEEVE EVENT
                            </h2>
                            <p className="paragraph-3">
                                JULY 20, 2024 @ HOME TABLE DELI • 1:00 PM TO 5:00 PM
                            </p>
                        </div>
                    </div>
                </div>
            </section>
    )
}

export default HomeSection2;