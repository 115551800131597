import React from "react";
import '../assets/style/NJPH.css'
import HomeTopAppBar from "../components/HomeTopAppBar";
import HomeSection2 from "../components/HomeSection2";
import HomeVideoBg from "../components/HomeVideoBg";
import HomeSection3 from "../components/HomeSection3";
import HomeSection4 from "../components/HomeSection4";
import Footer from "../components/Footer";


function Home() {
    return (
        <>
            <HomeTopAppBar/>
            <HomeSection2/>
            <HomeVideoBg/>
            <HomeSection3/>
            <HomeSection4/>
            <Footer/>
        </>
    );
}

export default Home;