import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './assets/style/NJPH.css';
import Home from './pages/Home';
import UpcomingEvents from './pages/UpcomingEvents'
import PastEvents from './pages/PastEvents'
import AboutUs from './pages/AboutUs'
import Contact from './pages/Contact'
import MainHelmet from './components/MainHelmet'

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
      <MainHelmet/>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/upcoming-events" element={UpcomingEvents} />
          <Route path="/past-events" element={PastEvents} />
          <Route path="/about-us" element={AboutUs} />
          <Route path="/contact" element={Contact} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
