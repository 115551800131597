import React from "react";
import { Link } from "react-router-dom";
import NjPhLogo from '../assets/images/nj-ph-logo.png'

function Footer() {
    return (
        <section className="footer-dark">
            <div className="container-7">
                <div className="footer-wrapper">
                    <Link to="/" className="footer-brand w-inline-block">
                        <img 
                            src={NjPhLogo}
                            loading="lazy" 
                            sizes="150px"
                            alt="NJ Bunnies Logo" // Provide a meaningful alt text
                            className="image-17"
                        />
                    </Link>
                    <div className="footer-content">
                        <div id="w-node-_4a2d2864-d793-9743-e671-daf70fb40ba9-38a13a3a" className="footer-block">
                            <div className="title-small">EVENTS</div>
                            <Link to="/upcoming-events" className="footer-link">Upcoming</Link>
                            <Link to="/past-events" className="footer-link">Past events</Link>
                            <Link to="/contact" className="footer-link">Contact Us</Link>
                        </div>
                        <div id="w-node-_4a2d2864-d793-9743-e671-daf70fb40bb2-38a13a3a" className="footer-block">
                            <div className="title-small">COMMUNITY LINKS</div>
                            <a href="https://t.me/newjeans_tg" target="_blank" rel="noopener noreferrer" className="footer-link">NewJeans Telegram Group</a>
                            <a href="/" className="footer-link">Other community groups</a>
                            <a href="/" className="footer-link">More Community groups</a>
                        </div>
                        <div id="w-node-_4a2d2864-d793-9743-e671-daf70fb40bbd-38a13a3a" className="footer-block">
                            <div className="title-small">About</div>
                            <a href="/" className="footer-link">Terms & Conditions</a>
                            <a href="/" className="footer-link">Privacy policy</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-divider"></div>
            <div className="footer-copyright-center">Copyright © 2024 Club0722</div>
            <div className="footer-version">Version 0.0.2</div>
        </section>
    );
}

export default Footer;