// src/pages/AboutUs.js
import React from 'react';

function AboutUs() {
    return (
        <div>
            <h1>About Us</h1>
            {/* Add your content here */}
        </div>
    );
}

export default AboutUs;
